import React from 'react';
import logo from './logo.svg';
import flag from './taiwan-national-flag.jpg';
import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD0CoP8rE59Anz4Y9o5qGPXcby85dHeTcc",
  authDomain: "taiwanisasovereignstate.firebaseapp.com",
  projectId: "taiwanisasovereignstate",
  storageBucket: "taiwanisasovereignstate.appspot.com",
  messagingSenderId: "784969619618",
  appId: "1:784969619618:web:2026b3dae5e77bac3adffb",
  measurementId: "G-883TEZJHW7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={flag} alt="Taiwan National Flg" />
        <p>Welcome to TaiwanIsASovereignState.com!</p>
      </header>
    </div>
  );
}

export default App;
